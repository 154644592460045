import { call, put, takeEvery } from "redux-saga/effects";
import i18next from "i18next";
import * as actionTypes from "../../constants/ActionTypes/UserAccount";
import { _CALLGETAPI } from "../../api/apiHandler";
import { 
  _API_ENDPOINT, 
  USER_LANGUAGE_CODE_TYPE, 
  LOCALSESSIONDATAKEYS, 
  SIGNUPTYPE, 
  SUBSCRIPTIONKIND, 
  _BROTHER_PLUS_API_ENDPOINTS } from "../../constants/AppConstants";
import { setUserData, setLocalSessionData, getLocalSessionData } from "../../utils/Authentication/handleUserSession";
import { setLangCode } from "../../utils/Internationalization/handleAppInternationalization";
import { isAdministratorRole } from "../../constants/UserRoles";
import { 
  currentUserRolesArr, 
  determineLanguageCodeForPayload, 
  getSignupTypeBy_SubscriptionKind, 
  getSubscriptionkindBy_SignupType, 
  Is_BrotherPlus_Signup } from "../../constants/AppMethod";
import { WebCustomerRoutePaths, WebHardwareInclusiveRoutePath } from "../../constants/Routes";

class DetailedError extends Error{
  constructor(message, extraInfo) {
    super(message);
    this.name = "UserAccount_RedirectErr";
    this.extraInfo = extraInfo;
  }
}

function _FETCHUSERACCOUNTAPI(ADB2C, UID, deviceId, type) {
  const IsBrotherPlus_Signup = Is_BrotherPlus_Signup();
  const endpoint = (IsBrotherPlus_Signup) ? _BROTHER_PLUS_API_ENDPOINTS.GET_USER_ACCOUNT : _API_ENDPOINT.GET_USER_ACCOUNT;
  const UserCountryCode = getLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE);
  const UserLanguageCode = determineLanguageCodeForPayload();
  const SIGNUP_TYPES = [SIGNUPTYPE.WEB, SIGNUPTYPE.HARDWARE_INCLUSIVE, SIGNUPTYPE.BROTHER_PLUS];
  const payload = {
    params: {
      adb2cId: ADB2C,
      UserId: (UID) ? UID : "",
      deviceId: (deviceId) ? deviceId : "",
      countryCode: UserCountryCode,
      languageCode: UserLanguageCode,
      subscriptionKind: type !== null ? getSubscriptionkindBy_SignupType(type) : null
    },
  };
  return _CALLGETAPI(endpoint, payload)
    .then((response) => {
      const currentUserRoles = currentUserRolesArr();
      if(SIGNUP_TYPES.includes(type) === false){
        if(
            (!response.userId || !response.deviceId) 
            && !isAdministratorRole(currentUserRoles)
          ){
            throw new DetailedError(i18next.t("ERROR_CONTENT.ERR_INFO.TECHNICAL_ERROR"), {subscriptionKind: response?.subscriptionKind});
          }
      }
      const UID = getLocalSessionData(LOCALSESSIONDATAKEYS.UID);
      const deviceID = getLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID)
      if(!UID && response.userId){
        setLocalSessionData(LOCALSESSIONDATAKEYS.UID, response.userId);
      }
      if(!deviceID && response.deviceId){
        setLocalSessionData(LOCALSESSIONDATAKEYS.DEVICE_ID, response.deviceId);
      }
      const BOLUserdata = {
        "personal_details": {},
        "billing_address": {},
        "shipping_address": {}
      };
      setLocalSessionData(LOCALSESSIONDATAKEYS.ECOPROCTRYCODE, response.countryCode.toUpperCase());
      if(SIGNUP_TYPES.includes(type) === false){
        setLangCode(response.languageCode, USER_LANGUAGE_CODE_TYPE); 
      }
      const formedUserData = {
        ...BOLUserdata, 
        personal_details: {
          'firstName': response.firstName,
          'lastName': response.lastName,
          'emailAddress': response.emailAddress,
          'displayName': response.displayName,
          'mobileNumber': response.mobileNumber,
          'telephoneNumber': response.phoneNumber
        },
        billing_address : response.billingAddress,
        shipping_address: response.shippingAddress,
        businessModel: response.personalOrBusiness,
        vatId: response.vatNumber,
        taxId: response.taxCode,
        langCode: response.languageCode,
        countryCode: response.countryCode,
        sapBpId: response.businessPartnerId,
        marketingEmails: response.marketingEmails,
        SubscriptionKind: response.subscriptionKind
      }
      setUserData(formedUserData);
      return response;
    })
    .catch((error) => {
      throw error;
    });
}

function* fetchUserAccount(action) {
  try {
    const ADB2CID= action.ADB2C;
    const UID= action.UID;
    const deviceID= action.deviceId;
    const type = action.signUpType;

    if(ADB2CID){
      const userAccount = yield call(
        _FETCHUSERACCOUNTAPI,
        ADB2CID,
        UID,
        deviceID,
        type
      );
      if(userAccount.adB2CUserId === ADB2CID){      
        yield put({
          type: actionTypes.FETCH_USER_ACCOUNT_SUCCESS,
          userAccount: userAccount,
        }); 
      } else {
        throw new DetailedError(i18next.t("ERROR_CONTENT.ADB2C_NOT_ASSOCIATED_WITH_BOL"), {subscriptionKind: userAccount?.subscriptionKind});
      }
    } else{
      yield put({
        type: actionTypes.FETCH_USER_ACCOUNT_ERROR,
        message: i18next.t('ERROR_CONTENT.INFO_GENERAL')
      }); 
    }
  } catch (e) {
    let redirectPage = WebCustomerRoutePaths.SELECT_PRINTER;
    const msg = (e?.response?.status === 404) ? i18next.t('ERROR_CONTENT.ADB2C_NOT_ASSOCIATED_WITH_BOL') : e.message;
      if(e?.extraInfo?.subscriptionKind){
        setLocalSessionData(LOCALSESSIONDATAKEYS.SIGNUP_TYPE, getSignupTypeBy_SubscriptionKind(e?.extraInfo?.subscriptionKind));
      }
      switch (e?.extraInfo?.subscriptionKind){
        case SUBSCRIPTIONKIND.HARDWARE_INCLUSIVE:
          redirectPage = `${WebHardwareInclusiveRoutePath.SELECT_PRINTER}?fieldtestpass=a3230b5f-cf13-5883-90f6-256bf540ae03&request-id=c46afda9-f417-5563-b543-9a7d245ec161`;
          break;
        case SUBSCRIPTIONKIND.BROTHER_PLUS:
          redirectPage = `WebCustomerRoutePaths.SELECT_PRINTER?src=sitecore_brotherplus`;
          break;
        default:
          redirectPage = WebCustomerRoutePaths.SELECT_PRINTER;
          break;
      }
      yield put({
        type: actionTypes.FETCH_USER_ACCOUNT_ERROR,
        message: msg,
        redirectToLandingPage:true,
        redirectPage: redirectPage
      });
  }
}

function* UserAccount() {
  yield takeEvery(actionTypes.FETCH_USER_ACCOUNT, fetchUserAccount);
}

export default UserAccount;
