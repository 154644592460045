import { all } from "@redux-saga/core/effects";
import MyPlanMiddleware from "./MyPlan";
import ZuoraPaymentInfoRendering from "./MyAccount/ZuoraPaymentInfoRendering";
import ManageSubscription from "./MyAccount/ManageSubscription";
import Enrollment from "./MyAccount/Enrollment";
import DashboardDeviceInfo from "./Dashboard/DeviceInfo";
import DashboardOrderHistory from "./Dashboard/OrderHistory";
import FAQTopCategories from "./FAQ/FAQTopCategories";
import FAQSublist from "./FAQ/FAQSublist";
import FAQContent from "./FAQ/FAQContent";
import UpdateShippingAddr from "./Dashboard/UpdateShipping";
import MyAccount from './Dashboard/FetchMyAccount'
import BOLAPI from "./Signin/BOLAPI";
import UserAccount from "./UserAccount/UserAccount";
import CancelOrder from "./Dashboard/CancelOrder";
import GetCarbonSaving from "./CarbonSaving/CarbonSaving";
import SwapDevice from "./Dashboard/SwapDevice";
import DeviceCapability from "./DeviceInfo/DeviceCapability";
import SelectDevice from "./DeviceInfo/SelectDevice";
import DownloadOrderInvoice from "./Dashboard/DownloadOrderInvoice";
import UpdatePlan from "./Dashboard/UpdatePlan";
import PrinterModels from "./Web/Customer/PrinterModels";
import WebServicePlan from "./Web/Customer/ServicePlan";
import UpdateADB2CNewUserAccount from "./Web/Customer/UpdateADB2CNewUserAccount";
import PreEnrollment from "./Web/Customer/PreEnrollment";
import UserSubscriptionInfo from "./Web/Customer/UserSubscriptionInfo";
import GetPreEnrollmentInfo from "./Web/Customer/GetPreEnrollmentInfo";
import VerifyPreEnrollmentInfo from "./Web/Customer/VerifyPreEnrollmentInfo";
import PrinterTypes from "./Web/Customer/PrinterTypes";
import SignUpUserFeedback from "./Dashboard/SignUpUserFeedback";
import PaymentOptions from "./MyAccount/PaymentOptions";
import UserSubscriptionAccountSummary from "./Dashboard/UserSubscriptionAccountSummary";
import GetUserBOCIdByADB2CId from "./Web/Customer/GetUserBOCIdByADB2CId";
import GetUserPaymentDetails from "./Web/Customer/GetUserPaymentDetails";
import GetPrintActivity from "./PrintActivity/PrintActivity";
import AdminSagas from "./AdminPortal/AdminSagasIndex";
import CsAgentSagas from "./CSAgentPortal/CSAgentSagasIndex";
import PlansLandingPage from "./PlansLandingPage";
import UpdateCustomerProfile from "./MyAccount/UpdateCustomerProfile";
import DeviceRegistration from "./DeviceInfo/DeviceRegistration";
import GetDashboardPreEnrollInfo from "./Web/Customer/GetDashboardPreEnrollInfo";
import GetBenefitOfSupplies from "./GetBenefitOfSupplies";
import AsyncEnrollmentRetry from "./Dashboard/AsyncEnrollmentRetry";
import GetPromotionBannerStatus from "./Web/Customer/GetPromotionBannerStatus";
import GetRegistrationType from "./Web/Customer/GetRegistrationType";
import GetDeviceRegistration from "./Web/Customer/GetDeviceRegistration";
import TokenAuthentication from "./Web/Customer/TokenAuthentication";
import GetBOCWebDeviceCapability from "./Web/Customer/GetBOCWebDeviceCapability";
import HWInclusive_ManageInstantPayment from "./HardwareInclusive/HWInclusive_ManageInstantPayment";
import GetHWPrinterFeaturesByPrinterType from "./HardwareInclusive/GetHWPrinterFeaturesByPrinterType";
import GetHWServicePlan from "./HardwareInclusive/GetHWServicePlan";
import GetHWPrinterByFeatures from "./HardwareInclusive/GetHWPrinterByFeatures";
import GetUserSubscription from "./Dashboard/GetUserSubscription";
import GetCancellationFee from "./Dashboard/GetCancellationFees";
import brotherPlusSagasIndex from "../store/brotherPlusSagasIndex";
import GetCancelSubscriptionStatus from "./Dashboard/GetCancelSubscriptionStatus";
import ReactivateSubscription from "./Dashboard/ReactivateSubscription";

export default function* saga() {
    yield all([
      MyPlanMiddleware(),
      ZuoraPaymentInfoRendering(),
      ManageSubscription(),
      Enrollment(),
      DashboardDeviceInfo(),
      DashboardOrderHistory(),
      FAQTopCategories(),
      FAQSublist(),
      FAQContent(),
      MyAccount(),
      UpdateShippingAddr(),
      SelectDevice(),
      BOLAPI(),
      UserAccount(),
      CancelOrder(),
      GetCarbonSaving(),
      SwapDevice(),
      DeviceCapability(),
      DownloadOrderInvoice(),
      UpdatePlan(),
      PrinterTypes(),
      PrinterModels(),
      WebServicePlan(),
      UpdateADB2CNewUserAccount(),
      PreEnrollment(),
      UserSubscriptionInfo(),
      GetPreEnrollmentInfo(),
      VerifyPreEnrollmentInfo(),
      SignUpUserFeedback(),
      PaymentOptions(),
      UserSubscriptionAccountSummary(),
      GetUserBOCIdByADB2CId(),
      GetUserPaymentDetails(),
      GetPrintActivity(),
      AdminSagas(),
      CsAgentSagas(),
      PlansLandingPage(),
      UpdateCustomerProfile(),
      DeviceRegistration(),
      GetBenefitOfSupplies(),
      AsyncEnrollmentRetry(),
      GetDashboardPreEnrollInfo(),
      GetPromotionBannerStatus(),
      GetRegistrationType(),
      GetDeviceRegistration(),
      TokenAuthentication(),
      GetBOCWebDeviceCapability(),
      HWInclusive_ManageInstantPayment(),
      GetHWPrinterFeaturesByPrinterType(),
      GetHWServicePlan(),
      GetHWPrinterByFeatures(),
      GetUserSubscription(),
      GetCancellationFee(),
      brotherPlusSagasIndex(),
      GetCancelSubscriptionStatus(),
      ReactivateSubscription()
    ])
  }